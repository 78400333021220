@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/corner-radius';

.error-page {
  background-color: lightmode.$background-primary;
  color: lightmode.$primary;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  &__content {
    background-color: white;
    border: 1px solid lightmode.$ui-shimmer;
    border-radius: corner-radius.$s;
    padding: spacing.$xxl spacing.$l;
    margin: spacing.$m;
    max-width: 500px;
    align-self: center;
    text-align: center;

    @media (min-width: 600px) {
      padding: spacing.$xxl;
    }
  }

  &__title {
    margin-bottom: spacing.$m;
  }

  &__text {
    margin-bottom: spacing.$l;
  }

  &__button {
    width: 100%;
  }
}
